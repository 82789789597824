import { SessionMaybe } from '@/providers/auth0/auth0.types';
import Market from '../../../models/market';
import Partner from '../../../models/partner/partner';
import {
  formatVersatileCard,
  hydrateSections,
} from '../../../utils/contentful-data-formatters';
import { MicrocopyGPT } from '../../../utils/contentful-data-formatters/contentful-data-formatters.types';
import { CarouselItemsResponse } from './home-page-module.types';

export const processPersonalisedSection = async (
  user: string,
  personalisedSectionData: any,
  market: Market,
  b2bToken: string,
  rateUpToText: string,
  rateWasText: string,
  similarPartners: string,
) => {
  const personalisedSectionSlug =
    personalisedSectionData?.paidForMerchant[0]?.slug;

  if (
    !user ||
    !personalisedSectionData ||
    !market ||
    !b2bToken ||
    !rateUpToText ||
    !rateWasText ||
    !personalisedSectionSlug
  ) {
    return {
      titleLabel: null,
      paidForMerchant: null,
      paidForMerchantHeroImage: null,
    };
  }

  const partnerInfo = new Partner(market, b2bToken, personalisedSectionSlug);

  try {
    await partnerInfo.isReady();
  } catch {
    return {
      titleLabel: null,
      paidForMerchant: null,
      paidForMerchantHeroImage: null,
    };
  }

  const highestRate = partnerInfo.getHighestRateMechanic();

  return {
    titleLabel: personalisedSectionData?.titleLabel || '',
    paidForMerchant: {
      ...personalisedSectionData.paidForMerchant[0],
      id: partnerInfo.id,
      mechanicId: partnerInfo.mechanicId,
      description: partnerInfo.description,
      destinationUrl: partnerInfo.destinationUrl,
      rate: highestRate?.rate ?? null,
      wasRate: highestRate?.wasRate
        ? `${rateWasText} ${highestRate.wasRate}`
        : null,
      isSpeedyAwarding: highestRate?.isSpeedyAwarding ?? false,
      ctaLabel: similarPartners,
      categoryUrl: partnerInfo.category?.url
        ? `/${partnerInfo.category.url}`
        : '/retailers',
    },
    paidForMerchantHeroImage: {
      ...personalisedSectionData?.paidForMerchantHeroImage,
    },
  };
};

export const formatCarouselBanners = async (
  carouselBanners: CarouselItemsResponse,
  market: Market,
  b2bToken: string,
  microcopy: MicrocopyGPT,
  session: SessionMaybe,
) => {
  return Promise.all(
    carouselBanners.map(async ({ content }) => {
      const hydratedVCC = await hydrateSections(
        content,
        market,
        b2bToken,
        session,
      );

      const {
        content: { partner },
        image,
      } = formatVersatileCard(hydratedVCC, microcopy);

      return {
        partner,
        image,
        buttonText: microcopy.shopnow_loggedinctalabel,
      };
    }),
  );
};
